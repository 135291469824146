@import "./variables.scss";
@import "./ButtonColor.scss";
.button {
  border: none;
  border-radius: 2px;
  padding: 9px 18px;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  cursor: pointer;
  color: white;
  box-shadow: 0 0 4px #999;
  outline: none;
  font-weight: bold;
  background-position: center;
  transition: background 0.8s;
  // svg{
  //   height: 30px;
  //   width: 30px;
  // }
}

//OK button, blue color
.btn-ok {
  background-color: #2196f3;
}
.btn-ok:hover {
  background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%)
    center/15000%;
}
.btn-ok:active {
  background-color: #6eb9f7;
}

//Delete button, red color
.btn-del {
  background-color: $red;
}
.btn-del:hover {
  background: lighten($red, 10%)
    radial-gradient(circle, transparent 1%, lighten($red, 10%) 1%) center/15000%;
}
.btn-del:active {
  background-color: lighten($red, 20%);
}

//Cancel button, gray color
.btn-cancel {
  background-color: lighten($dark-gray-1, 30%);
  color: black;
}
.btn-cancel:hover {
  background: lighten($dark-gray-1, 40%)
    radial-gradient(circle, transparent 1%, lighten($dark-gray-1, 40%) 1%)
    center/15000%;
}
.btn-cancel:active {
  background-color: lighten($dark-gray-1, 50%);
}

@each $color in $colors-map {
  .btn-#{nth($color, 1)} {
    background-color: nth($color, 2);
  }
  .btn-#{nth($color, 1)}:hover {
    background: lighten(nth($color, 2), 10%)
      radial-gradient(circle, transparent 1%, lighten(nth($color, 2), 10%) 1%)
      center/15000%;
  }
  .btn-#{nth($color, 1)}:active {
    background-color: lighten(nth($color, 2), 20%);
  }
}


.button:active {
  background-size: 100%;
  transition: background 0s;
}

.button:disabled {
  background: lighten($dark-gray-1, 30%)
    radial-gradient(circle, transparent 1%, lighten($dark-gray-1, 30%) 1%)
    center/15000%;
}

