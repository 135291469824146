$blue-5: #011f4b;
$blue-4: #03396c;
$blue-3: #005b96;
$blue-2: #6497b1;
$blue-1: #b3cde0;
$brown: #966842;
$red: #f44747;
$yellow: #eedc31;
$green: #7fdb6a;
$dark-green: hsl(109, 56%, 38%);
$blue: #0e68ce;
$white: #ffffff;
$purple-2: #7289da;
$gray-1: #e0e0e0;
$dark-gray: #888;
$dark-gray-1: #2c2f33;
$dark-gray-2: #23272a;
$dark-gray-listmanga: #fafafa;
$orange-1: #eb9422;