.group-input{
    width: 100%;
    margin-bottom: 10px;
}
.pure-material-textfield-filled {
    position: relative;
    display: inline-block;
    font-family: var(--pure-material-font, "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system);
    font-size: 16px;
    line-height: 1.5;
    overflow: hidden;
    width: 100%;
}

/* Input, Textarea */
.pure-material-textfield-filled > input,
.pure-material-textfield-filled > textarea {
    display: block;
    box-sizing: border-box;
    margin: 0;
    border: none;
    border-top: solid 27px transparent;
    border-bottom: solid 1px rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
    border-radius: 4px 4px 0 0;
    padding: 0 12px 10px;
    width: 100%;
    height: inherit;
    color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.02);
    box-shadow: none; /* Firefox */
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    caret-color: rgb(33, 150, 243);
    transition: border-bottom 0.2s, background-color 0.2s;
}

.pure-material-textfield-filled > textarea{
    height: 100%;
    resize: none;
}

/* Span */
.pure-material-textfield-filled > input + span,
.pure-material-textfield-filled > textarea + span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    box-sizing: border-box;
    padding: 7px 12px 0;
    color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
    font-size: 75%;
    line-height: 18px;
    pointer-events: none;
    transition: color 0.2s, font-size 0.2s, line-height 0.2s;
}

/* Underline */
.pure-material-textfield-filled > input + span::after,
.pure-material-textfield-filled > textarea + span::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 2px;
    background-color: rgb(33, 150, 243);
    transform-origin: bottom center;
    transform: scaleX(0);
    transition: transform 0.3s;
}

/* Hover */
.pure-material-textfield-filled > input:hover,
.pure-material-textfield-filled > textarea:hover {
    border-bottom-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.08);
}

/* Placeholder-shown */
.pure-material-textfield-filled > input:not(:focus):placeholder-shown + span,
.pure-material-textfield-filled > textarea:not(:focus):placeholder-shown + span {
    font-size: inherit;
    line-height: 48px;
    color: #777777;
}

/* Focus */
.pure-material-textfield-filled > input:focus,
.pure-material-textfield-filled > textarea:focus {
    outline: none;
}

.pure-material-textfield-filled > input:focus + span,
.pure-material-textfield-filled > textarea:focus + span {
    color: rgb(33, 150, 243);
}

.pure-material-textfield-filled > input:focus + span::before,
.pure-material-textfield-filled > textarea:focus + span::before {
    opacity: 0.12;
}

.pure-material-textfield-filled > input:focus + span::after,
.pure-material-textfield-filled > textarea:focus + span::after {
    transform: scale(1);
}

/* Disabled */
.pure-material-textfield-filled > input:disabled,
.pure-material-textfield-filled > textarea:disabled {
    border-bottom-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
    color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.24);
}

.pure-material-textfield-filled > input:disabled + span,
.pure-material-textfield-filled > textarea:disabled + span {
    color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
}
/* Required */
.pure-material-textfield-filled > input:required,
.pure-material-textfield-filled > textarea:required {
    border-bottom-color: rgba(var(--pure-material-onsurface-rgb, 255, 0, 0), 0.38);
}

.field-alert{
    display: inline-block;
    width: 100%;
    font-family: inherit;
    font-size: 60%;
    position: inherit;
    border-radius: 0 0 4px 4px;
    background-color: yellow;
    p{
        margin: 8px 5px;
    }    
}

.hide{
    display: none;
}